@font-face {
    font-family: 'Montserrat';
    src: url("/src/presentation/assets/fonts/Montserrat/static/Montserrat-Regular.ttf");
}

.swal2-container {
    z-index: 2600 !important;
}

.button-popup {
    height: 40px;
    line-height: 20px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 6px;
    padding: 10px 5px;
    color: #ffffff;
    background-color: #770027;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 0;
    width: 100%
}

.button-popup:hover {
    background: #CCBA7C;
    border: 0;
}
