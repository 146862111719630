.activeStyle {
    text-decoration: none;
}

.activeStyle .MuiListItemButton-root {
    color: #fff;
    background: #7d112a;
}

.activeStyle .MuiListItemButton-root:hover {
    background-color: #7d112a;
    color: #fff;
}

.activeStyle .MuiListItemButton-root .MuiListItemIcon-root {
    color: #d5bf7d;
}

.notActiveStyle .MuiListItemButton-root .MuiListItemIcon-root {
    color: #fff;
}

.MuiDrawer-docked .MuiDrawer-paper {
    background-color: #272626;
}

.notActiveStyle {
    text-decoration: none;
    color: #fff !important;
}

.footer {
    position: relative;
    /*z-index: 1650 !important;*/
}

.footer-link-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
}
